import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./EditCollection.scss";
import FormatField from "App/Components/UI/FormatField";
import Button from "App/Components/UI/Button";
import ReloadIcon from 'Assets/Icons/ReloadIcon.svg';
import SkeletonLoader from "App/Components/UI/SkeletonLoader";

import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { categorySort, copyClipHandler, getBlockChainLable, objectToFormdata, stringImageSrcToBlob } from "App/helpers/utilities";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import TextArea from "App/Components/UI/TextArea";
import SelectBox from "App/Components/UI/SelectBox";
import TermsTable from "../../Components/TermsTable";
import TermsConditions from "../../Components/TermsConditions";
import copyIcon from "Assets/Icons/copy_icon.svg";
import useShapes from "App/hooks/use-shapes";
import MetricsData from "./SubComponents/MetricsData";
import closeIcon from "Assets/Icons/close.svg";
import PiecesList from "./SubComponents/PiecesList";
import PieceDetailsView from "./SubComponents/PieceDetailsView";
import { getBlockchainIcon, marketplaceSupportedChains } from "App/helpers/blockchain";
import CreatorCard from "App/Components/UI/CreatorCard";
import ProofOfCraft from "./SubComponents/ProofOfCraft";
import useProofOfCraftApiHelper from "./SubComponents/ProofOfCraft/API";
import ImageUploadWithCrop from "App/Components/ImageUploadWithCrop";
import Tabs from "App/Components/Tabs";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
const EditCollection = (props) => {
  const formGroup = useMemo(() => {
    return {
      name: {
        value: undefined,
        isRequired: true,
      },
      blockchainTypeId: {
        value: undefined,
        isRequired: true,
      },
      collectionId: {
        value: undefined,
        isRequired: true,
      },
      blockchainSource: {
        value: "blockchain",
        isRequired: true,
      },
      description: {
        value: undefined,
        isRequired: false,
      },
      categoryId: {
        value: undefined,
        isRequired: true,
      },
      firstSubcategoryId: {
        value: undefined,
        isRequired: true,
      },
      // secondSubcategoryId: {
      //   value: undefined,
      //   isRequired: false,
      // },
      communityId: {
        value: undefined,
        isRequired: true,
      },
      marketplace: {
        value: undefined,
        isRequired: false,
      },
      instagram: {
        value: undefined,
        isRequired: false,
      },
      twitter: {
        value: undefined,
        isRequired: false,
      },
      discord: {
        value: undefined,
        isRequired: false,
      },
      email: {
        value: undefined,
        isRequired: false,
      },
      youtube: {
        value: undefined,
        isRequired: false,
      },
      website: {
        value: undefined,
        isRequired: false,
      },
      termsId: {
        value: undefined,
        isRequired: false,
      },
      category: {
        value: undefined,
        isRequired: false,
      },
      firstSubcategory: {
        value: undefined,
        isRequired: false,
      },
      secondSubcategory: {
        value: undefined,
        isRequired: false,
      },
      file: {
        value: undefined,
        isRequired: false,
      },
      collectionStorageId: {
        value: undefined,
        isRequired: false,
      },
      collectionUtilitiesIds: {
        value: undefined,
        isRequired: false,
      },
      collectionEditionsId: {
        value: undefined,
        isRequired: false,
      },
      collectionFormatIds: {
        value: [],
        isRequired: false,
      },
      contractAddress: {
        value: [],
        isRequired: true,
      },
      isCustomSocial:{
        value: false,
        isRequired: false,
      },
      collectionTypeIds: {
        value: [],
        isRequired: false,
      },
      collectionTagIds: {
        value: [],
        isRequired: false,
      },
      primaryMintUrl: {
        value: undefined,
        isRequired: false,
      },
    }
  }, []);
  const { state, search } = useLocation()
  const { deleteProofOfCraftFiles } = useProofOfCraftApiHelper();
  const id = search?.split('?')[1];
  const isEditPage = !!id;
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [details, setDetails] = useState({});
  const [blockchainTypeList, setBlockchainTypeList] = useState([]);
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [collectionImageLoading, setCollectionImageLoading] = useState(true);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [collagePictureLoading, setCollagePictureLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [collagePicture, setCollagePicture] = useState(null);
  const [category, setCategory] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [firstSubCategory, setFirstSubCategory] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [sampleAssetData, setSampleAssetData] = useState({});
  const [collectionStorageList, setCollectionStorageList] = useState([]);
  const [collectionEditionsList, setCollectionEditionsList] = useState([]);
  const [collectionFormatList, setCollectionFormatList] = useState([]);
  const [collectionTypeList, setCollectionTypeList] = useState([]);
  const [collectionTagList, setCollectionTagList] = useState([]);
  const [collectionUtilityList, setCollectionUtilityList] = useState([]);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [pieceDetails, setPieceDetails] = useState(null);
  const [isCollectionValid, setIsCollectionValid] = useState(false);
  // const isVerfifyPage = pathname?.includes('verification');
  const isVerfifyPage = props?.isVerifyCollection;
  const isTemporary = state?.temporary;
  const [metricsData, setMetricsData] = useState(null);
  const [shapes, setShapes] = useState(null);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [creatorScreenshotInfo, setCreatorScreenshotInfo] = useState(null);
  const [screenshotTaken, setScreenshotTaken] = useState(false);
  const [collectionId, setCollectionId] = useState('');
  const [showBlockchainSourceList, setShowBlockchainSourceList] = useState(false);
  const [blockchainTypePlaceholder, setBlockchainTypePlaceholder] = useState('');
  const [isCollectionFoundSearch, setIsCollectionFoundSearch] = useState(false);
  const [policyDetails, setPolicyDetails] = useState();
  const [contractAddress, setContractAddress] = useState(null);
  const [dataFetched, setDataFetched] = useState({
    category: false,
    data: false,
    communityList: false
  });
  const [nsfwValue, setNsfwValue] = useState('no');
  const [blockchainData, setBlockchainData] = useState(null);
  const [activeTab, setActiveTab] = useState("details")
  const profileWrapRef = useRef(null);
  const navigate = useNavigate();
  const { getShapes } = useShapes();

  const [proofOfCrafts, setProofOfCrafts] = useState([]);
  const [deletedProofOfCrafts, setDeletedProofOfCrafts] = useState([]);
  const blockchainSourceList = useMemo(() => ([{ name: "Marketplace", value: "marketplace", id:"marketplace"},{name: "Blockchain", value: "blockchain", id:"blockchain"}]), []);
useEffect(()=>{
  setCollectionId(id)
},[id])
useEffect(()=>{
  if(props?.communityId){
    inputOnChange({ communityId: props?.communityId });
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[props?.communityId])

  const getCollectionUtilityResponse = useCallback(
    (res) => {
      setCollectionUtilityList(res?.data?.list);
    },
    []
  );

  const reloadResult = useCallback(
    (res) => {
      setReloading(false);
      setCollagePicture(res?.data?.collage)
      inputOnChange({ collage: res?.data?.collage });
      if(!res?.data?.collage){
        dispatch("showToast", {
          toast: { toastMode: "error", message: "Not able to generate collage" },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const updateCollage = (collectionId,blockchainTypeId)=>{
    setReloading(true)
    triggerAPI(
      {
        url: `user/nft-collection/images`,
        method: "post",
        data:{collectionId,blockchainTypeId}
      },
      reloadResult,
      (err) => {
        setReloading(false)
        dispatch("showToast", {
          toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
        });
      }
    );
  }

  const getCollectionUtility = useCallback(() => {
    triggerAPI(
      {
        url: `community/collection_utilities/get`,
        method: "get",
      },
      getCollectionUtilityResponse
    );
  }, [getCollectionUtilityResponse, triggerAPI]);
  const getCategoryResponse = useCallback(
    (res) => {
      let updatedList=categorySort(res?.data?.list);
      setCategory(updatedList);
      setDataFetched((prev) => {
        return { ...prev, category: true };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getCategoryResponse, triggerAPI]);

  const getTermsResponse = useCallback(
    (res) => {
      setTermsList(res?.data?.list.sort((a, b) => a.id - b.id));
    },
    []
  );

  const getTermsList = useCallback(() => {
    triggerAPI(
      {
        url: `community/collection-terms/get`,
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getTermsResponse
    );
  }, [getTermsResponse, triggerAPI]);

  const getCollectionStorageResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionStorageList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionStorage = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_storage/get`,
        method: "get",
      },
      getCollectionStorageResponse
    );
  }, [dispatch, getCollectionStorageResponse, triggerAPI]);

  const getCollectionTypeResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionTypeList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionType = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_type/get`,
        method: "get",
      },
      getCollectionTypeResponse
    );
  }, [dispatch, getCollectionTypeResponse, triggerAPI]);

  const getCollectionTagResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionTagList(res?.data?.list.sort((a,b) => a.name > b.name ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionTag = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_tag/get`,
        method: "get",
      },
      getCollectionTagResponse
    );
  }, [dispatch, getCollectionTagResponse, triggerAPI]);

  const getCollectionFormatResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");      
      setCollectionFormatList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionFormat = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_format/get`,
        method: "get",
      },
      getCollectionFormatResponse
    );
  }, [dispatch, getCollectionFormatResponse, triggerAPI]);

  const getCollectionEditionsResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionEditionsList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionEditions = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_editions/get`,
        method: "get",
      },
      getCollectionEditionsResponse
    );
  }, [dispatch, getCollectionEditionsResponse, triggerAPI]);

  const getBlockChainTypeResponse = useCallback(
    (res) => {
     // dispatch("hideSpinner");
      const list = res?.data?.rows?.sort((a,b)=>a.sortOrder>b.sortOrder?1:-1).map((item) => {
        const notActive =  item?.code !== 'cardano';
        return { name: item?.name, id: item?.id, value: item?.id, code: item?.code, notActive, icon: getBlockchainIcon(item?.code), showIcon: true }
      });
      
      setBlockchainTypeList(list);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getBlockChainType = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/blockchain-types/get`,
        method: "get",
      },
      getBlockChainTypeResponse
    );
  }, [dispatch, getBlockChainTypeResponse, triggerAPI]);

  useEffect(() => {
    getCategoryList();
    getTermsList();
    getCollectionStorage();
    getCollectionEditions();
    getCollectionFormat();
    getCollectionType();
    getCollectionTag();
    getBlockChainType();
    getCollectionUtility()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!collectionId && formValues?.collectionId && (formValues?.collectionId?.toLowerCase() !== details?.collectionId?.toLowerCase()) && formValues?.blockchainTypeId) {
      const nameSearch = setTimeout(() => {
        getDetailedCollectionByCollectionId(formValues?.collectionId, formValues?.blockchainTypeId, formValues?.blockchainSource);
      }, 1000);
      return () => {
        clearTimeout(nameSearch);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.collectionId, formValues?.blockchainTypeId]);

  const subCategoryHandler = useCallback( async (cId) => {
      let categoryTemp = category;
      const subCatgry = (await categoryTemp?.find((val) => val?.id === cId)?.subcategories) || [];
      setFirstSubCategory(subCatgry);
      return subCatgry;
  }, [category] );

  const secondSubCategoryHandler = useCallback(
    async (cId, keyName = "id", subCat) => {
      let subArr = subCat ?? firstSubCategory;
      const secondSub =
        (await subArr?.find((val) => val[keyName] === cId)
          ?.subcategories) || [];
      return secondSub;
    },
    [firstSubCategory]
  );
  useEffect(() => {
    if (dataFetched?.data && dataFetched?.category) {
      updateHandler(formValues);
    }      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);
  
  const updateHandler = useCallback(async (val) => {
    let data = val ?? formValues;
    const subCategory = await subCategoryHandler(data?.categoryId);
      const categoryVal = category?.find(
        (val) => val?.id === data?.categoryId
      )?.name;
      const firstSubcategoryVal = subCategory?.find(
        (val) => val?.id === data?.firstSubcategoryId
      )?.name;
      
      const cateData = {
        category: categoryVal,
        firstSubcategory: firstSubcategoryVal,
        // secondSubcategory: secondSubcategoryVal,
      };
    setFormvalues((prev) => {
      return { ...prev, ...cateData };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    category,
      formValues?.categoryId,
      formValues?.firstSubcategoryId,
      // formValues?.secondSubcategoryId,
  ]);

  // useEffect(() => {
  //   if (
  //     dataFetched?.category &&
  //     dataFetched?.data &&
  //     category &&
  //     formValues?.categoryId
  //   ) {
  //     updateHandler();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   dataFetched,
  //   category,
  //   formValues?.categoryId,
  //   formValues?.firstSubcategoryId,
  //   formValues?.secondSubcategoryId,
  //   formValues?.category,
  //   formValues?.firstSubcategory,
  //   formValues?.secondSubcategory,
  // ]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const makeFirstSubcategoryIdEmpty = () => {
    setTimeout(()=>{
      setFormvalues((prev) => {
        return {
          ...prev,
          firstSubcategoryId: '',
        };
      });
    },200)
  }
  const inputOnChange = (e) => {
    
    
    if (Object.keys(e)[0] === 'blockchainTypeId' &&  e?.blockchainTypeId) {
      

      let chain = blockchainTypeList.filter(b=>b.id === e.blockchainTypeId)?.[0];
      if (chain?.code)
      {    
            
        let canBeMarketplace = marketplaceSupportedChains().includes(chain.code);
        setShowBlockchainSourceList(canBeMarketplace)     
        e.blockchainSource = canBeMarketplace?'marketplace':'blockchain'        
        setBlockchainTypePlaceholder(getBlockChainLable({blockChainList: blockchainTypeList, blockChainId: e?.blockchainTypeId, marketplaceChains: canBeMarketplace?marketplaceSupportedChains():[]}))
      }      
    }
    if (Object.keys(e)[0] === 'blockchainSource' && e?.blockchainSource) {
      setBlockchainTypePlaceholder(getBlockChainLable({blockChainList: blockchainTypeList, blockChainId: formValues.blockchainTypeId, marketplaceChains: e.blockchainSource==='marketplace'?marketplaceSupportedChains():[]}))            
    }
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
    if (Object.keys(e)[0] === "categoryId") {
      subCategoryHandler(e?.categoryId);
      makeFirstSubcategoryIdEmpty()
    } else if (Object.keys(e)[0] === "firstSubcategoryId") {
      secondSubCategoryHandler(e?.firstSubcategoryId);
    }
  };
  useEffect(() => {
    if(screenshotTaken)
      sucessResponse({message: 'Community collection updated'})
    return () => {
      setScreenshotTaken(false)
      setCreatorScreenshotInfo(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenshotTaken])
  const sucessResponse = (res) => {
    props.onRefetchList && props.onRefetchList();
    gotToBack();
    dispatch("hideSpinner");
    dispatch("showToast", {
      toast: { toastMode: "success", message: res?.message },
    });
  }
  const getUpdateResult = useCallback(
    (res) => {      
      
      const { data = null } = res;
      
      deleteProofOfCraftFiles(deletedProofOfCrafts);
      if(!!data?.id) //community id
        setCreatorScreenshotInfo({
          blockChains: data?.blockchain,
          coverImage: data?.bannerPicture,
          profileImage: data?.displayPicture256,
          collectionList: data?.collectionFilePath256,
          creatorName: data?.name,
          id: data?.id,
        })
      else 
        sucessResponse(res);
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigate]
  );

  const actionHandler = (list,isVerifiedPage) => {
    let postUrl = isCollectionFoundSearch ? 'community/collection/add-to-creator' :
    id||collectionId || details?.collectionId === list?.collectionId 
      ?  isTemporary ? `community/collection/temp/update/${id||collectionId || details?.id}` :
      `community/collection/update/${id||collectionId || details?.id}`
      : 'community/collection/add-to-creator';
    

  //  const postUrl = isCollectionFoundSearch ? 'community/collection/temp/create' :isTemporary ? `community/collection/temp/update/${id}` : `community/collection/update/${id}`;
    let method = isCollectionFoundSearch ? 'post' :
    id||collectionId || details?.collectionId === list?.collectionId 
      ? 'put'
      : 'post';
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (policyDetails?.mintDate)
      {
        list.mintDate = policyDetails.mintDate;
      }
    if (list.assetCount||policyDetails?.assetCount)
      {
        list.assetCount = list.assetCount||policyDetails?.assetCount;
      }
      if (list.ownerCount||policyDetails?.ownerCount)
        {
          list.ownerCount = list.ownerCount||policyDetails?.ownerCount;
        }

    if (typeof list.file === "string") delete list.file;
    if (list.communityId === 0) { list.communityId = null}
    if (list.collectionId&&!isVerifiedPage){ list.collectionId = [list.collectionId]}
    
    if(method==="post"&&props?.community?.createdBy){
      list.createdBy=props?.community?.createdBy
    }
    list.collectionUtilitiesIds= list.collectionUtilitiesIds||[];
    list.collectionTagIds= list.collectionTagIds||[];
    list.collectionFormatIds= list.collectionFormatIds||[];
    const formData = objectToFormdata(list);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      (res)=>{
        if(isVerifiedPage){
          if(isCollectionFoundSearch){
            getUpdateResult({...res, data: res?.data?.community})
          }else{
            updateStatusHandler(res)
          }
        }else{
          getUpdateResult(res)
        }
      },
    );
  };

  const actionHandlerNew = (list) => {
    let postUrl = isCollectionFoundSearch ? 'community/collection/add-to-creator' :
    id|| collectionId || details?.collectionId === list?.collectionId 
      ?  isTemporary ? `community/collection/temp/update/${id||collectionId || details?.id}` :
      `community/collection/update/${id||collectionId || details?.id}`
      : 'community/collection/add-to-creator';
    

  //  const postUrl = isCollectionFoundSearch ? 'community/collection/temp/create' :isTemporary ? `community/collection/temp/update/${id}` : `community/collection/update/${id}`;
    let method = isCollectionFoundSearch ? 'post' :
    id|| collectionId || details?.collectionId === list?.collectionId 
      ? 'put'
      : 'post';
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (typeof list.file === "string") delete list.file;
    if (list.communityId === 0){ list.communityId = null}
    if(method==="post"&&props?.community?.createdBy){
      list.createdBy=props?.community?.createdBy
    }
    if (policyDetails?.mintDate)
      {
        list.mintDate = policyDetails.mintDate;
      }
    if (list.assetCount||policyDetails?.assetCount)
      {
        list.assetCount = list.assetCount||policyDetails?.assetCount;
      }
      if (list.ownerCount||policyDetails?.ownerCount)
        {
          list.ownerCount = list.ownerCount||policyDetails?.ownerCount;
        }
        list.collectionUtilitiesIds= list.collectionUtilitiesIds||[];
        list.collectionTagIds= list.collectionTagIds||[];
        list.collectionFormatIds= list.collectionFormatIds||[];

        
    const formData = objectToFormdata(list);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      (res)=>{
        if(isCollectionFoundSearch){
          getUpdateResult({...res, data: res?.data?.community})
        }else{
          updateStatusHandler(res)
        }
        
      },
    );
  }

  const updateStatusHandler = (collectionRes) => {
    triggerAPI(
      {
        url: isTemporary ? 'community/collection/temp/update-status' : 'community/collection/update-status',
        data: {
          id:id||collectionRes?.data?.id,
          status: 'active',
        },
        method: 'patch',
      },
      (res) => {
        const { message, data } = res;
        getUpdateResult({message, data: {...data?.community}})
      },
    );
  }

  function checkArrayAndCovertToJSON (checkData) {
    if (!!checkData && Array.isArray(checkData) && checkData?.length) {
      const filteredData = checkData?.filter((data)=>data);
      return filteredData?.length ? JSON.stringify(filteredData) : null;
    } else if (checkData && !Array.isArray(checkData)){
      return checkData
    } else {
      return null
    }
  }

  const saveHandler = useCallback(() => {
    if (isValid) {
      dispatch("showSpinner");
      const list = {...formValues, collage: collagePicture, isNsfw: nsfwValue === 'yes' ? true : false, proofOfCraft: checkArrayAndCovertToJSON(proofOfCrafts)}
      delete list.newtag;
        actionHandler(list,isVerfifyPage);
      // if (
      //   (list?.category && !list?.categoryId) ||
      //   (list?.firstSubcategory && !list?.firstSubcategoryId) ||
      //   (list?.secondSubcategory && !list?.secondSubcategoryId)
      // ) {
      //   if (list?.category && !list?.categoryId) {
      //     triggerAPI(
      //       {
      //         url: `category/create`,
      //         data: { name: list?.category },
      //         method: "post",
      //       },
      //       (res) => {
      //         list.categoryId = res?.data?.id;
      //         inputOnChange({ categoryId: res?.data?.id });
      //         createSubCategoryHandler(list);
      //       }
      //     );
      //   } else {
      //     createSubCategoryHandler(list);
      //   }
      // } else {
      //   actionHandler(list);
      // }
    } else {
      setTouched(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues, getUpdateResult, isValid, triggerAPI]);

  const handleCroppedImage = (blobData, imageUrl, callback) => {
    if (!!blobData) {
      if (blobData?.displayPicture) {
        inputOnChange({ file: blobData?.displayPicture });
        setDisplayPicture(imageUrl);
      }
      callback?.()
    } else {
      dispatch("showToast", {
        toast: {
          toastMode: "error",
          message: "Please upload an image",
        },
      });
    }
  }

  const handleCollectionStorage = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionStorageId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionStorageId: ids });
  };
  const handleCollectionEdition = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionEditionsId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionEditionsId: ids });
  };
  const handleCollectionUtility = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids =Array.isArray(formValues?.collectionUtilitiesIds)?[...formValues?.collectionUtilitiesIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));
    }
    inputOnChange({ collectionUtilitiesIds: ids?.length?ids:null });
  };
  const handleCollectionFormat = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids =Array.isArray(formValues?.collectionFormatIds)?[...formValues?.collectionFormatIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(formValues?.collectionFormatIds.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }    
    inputOnChange({ collectionFormatIds: ids?.length?ids:null });
  };
  const handleCollectionType = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids =Array.isArray(formValues?.collectionTypeIds)?[...formValues?.collectionTypeIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }
    inputOnChange({ collectionTypeIds: ids?.length?ids:null });
  };
  const handleCollectionTag = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids =Array.isArray(formValues?.collectionTagIds)?[...formValues?.collectionTagIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }
    inputOnChange({ collectionTagIds: ids?.length?ids:null });
  };
  
  const saveNewTagResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      getCollectionTag();
      inputOnChange({ newtag: "" });      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveNewTag = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/collection_tag/create",
          data,
          method: "post",
        },
        saveNewTagResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: "Tag creation failed" },
          });
        }
      );
    },
    [dispatch, saveNewTagResult, triggerAPI]
  );

  const handleNewCollectionTag = (e) => {    
    let data = { name: formValues.newtag }
    saveNewTag(data);
  };
  const handleTerms = (e) => {
    if(!isEditPage) {
      const id = e?.currentTarget?.dataset?.id;
      let value = parseInt(id);
      if (formValues?.termsId === value) {
        value = null;
      }
      inputOnChange({ termsId: value });
    }   
  };

  useEffect(() => {
    if ((id||collectionId)&&!details?.id) {
      getDetailedCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id,collectionId]);
  const getDetailedCollectionResult = useCallback(
    (res) => {
      setIsCollectionValid(true)
      const { data } = res;
      let details = { ...data };
      let ratios = details?.shapes?.length ? details?.shapes.map(shape => Number(shape.aspectRatio)) : details?.aspectRatios?.split(','); 
      setShapes(getShapes(ratios));
      setBlockchainData(details?.blockchainType)
      setMetricsData({...data});
      let newDetails = {
        name: data?.name ?? "",
        description: data?.description ?? "",
        blockchainTypeId: data?.blockchainTypeId,
        collectionId: data?.collectionId,
        website: data?.website ?? "",
        twitter: data?.twitter ?? "",
        instagram: data?.instagram ?? "",
        discord: data?.discord ?? "",
        youtube: data?.youtube ?? "",
        email: data?.email ?? "",
        communityId: data?.communityId || props?.communityId || 0,
        categoryId: data?.categoryId ?? "",
        marketplace: data?.marketplace ?? "",
        firstSubcategoryId: data?.firstSubcategoryId ?? "",
        // secondSubcategoryId: data?.secondSubcategoryId ?? "",
        collectionStorageId: data?.collectionStorageId,
        collectionEditionsId: data?.collectionEditionsId,
        collectionUtilitiesIds: data?.utilities?.length ? data?.utilities?.map(t=>t.id) : null,
        collectionFormatIds: data?.formats?.map(f=>f.id),
        collectionTypeIds: data?.types?.map(t=>t.id),
        collectionTagIds: data?.tags?.map(t=>t.id),
        termsId: data?.termsId ?? "",
        file: data?.filePath ?? "",
        isNsfw : data?.isNsfw,
        contractAddress: [data?.contractAddress],
        primaryMintUrl: data?.primaryMintUrl,
        isCustomSocial:!!data?.isCustomSocial 
      }
      if(data?.community){
        setCommunityList([data?.community]);
      }
      setFormvalues(newDetails);
      setNsfwValue(()=>data?.isNsfw ? 'yes': 'no')
      setDataFetched((prev) => {
        return { ...prev, data: true };
      });
      setDisplayPicture(data?.filePath);
      setCollagePicture(data?.collage);
      data.mintDate = dateValidate(data.mintDate);
      data.lockDate = dateValidate(data.lockDate);
      setDetails(data);
      let assets = data?.assets?.slice(1, 5); //don't include the first asset, its usually not an actual NFT
      if(assets?.length) {
          setSampleAssetData(assets[0] ?? data?.assets[0] ?? {});
      } else {
          setSampleAssetData(data?.assets?.length ? data?.assets[0] : {});
      }
  
      if(details?.proofOfCraft?.length) {
        setProofOfCrafts(details?.proofOfCraft)
      }
      setCollectionInfo({assets:data?.assets, collectionId: data?.collectionId})
      dispatch("hideSpinner");
      setCollectionLoading(false)
      setCollectionImageLoading(false);
      setCollagePictureLoading(false);
      if (!data?.collage)
      {
        if(!isCollectionFoundSearch){ // if the collection is not in the database this api is getting error so this api get only called only for edit collections
          reFetchCollectionData(true,false,data?.id);
        }
      }      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getDetailedCollectionByCollectionId = useCallback(
    (collectionId, blockChainId, blockchainSource) => {
      dispatch("showSpinner");
      const data = new URLSearchParams({
        search: [blockchainSource!=='marketplace'?collectionId:''],
        blockchainTypeId: blockChainId,
        networkType: "MAINNET",
        marketPlaceUrl: blockchainSource==='marketplace'?collectionId:''
      })
      triggerAPI(
        // isRequested ? {
        //   url: `community/collection/find?${data}`,
        //   method: "get"
        // } :
        // {
        //   url:`community/collection/temp/get-one`,
        //   method: "post",
        //   data: { id: collectionId }
        // },
        {
          url: `community/collection/find?${data}`,          
          method: "get"
        },
        (res) => {
          const { data } = res;
          dispatch("hideSpinner");
          if (data?.policyValid) {
            setIsCollectionValid(true)
            setIsCollectionFoundSearch(true);
            setPolicyDetails(data?.policyDetails);
            setContractAddress(data?.policyDetails?.contractAddress)
            if (!data?.policyDetails?.collage)
            {
              updateCollage(data?.policyDetails?.collectionId, data?.policyDetails.blockchainTypeId);              
            }
          } else {
            setIsCollectionFoundSearch(false);
            setIsCollectionValid(false)
            dispatch('showToast', { toast: { toastMode: 'error', message: 'Policy ID not valid' } })
          }
        },(err)=>{
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
          });
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      getDetailedCollectionResult,
      triggerAPI,
      formValues?.collectionId,
    ]
  );

  useEffect(() => {
    if (isCollectionFoundSearch) {
      getDetailedCollectionResult({ data: policyDetails });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDetails]);

  const dateValidate = useCallback((date) => {
    const dateValue = new Date(date);
    if (isNaN(dateValue)) {
      return '';
    } else {
      return dateValue.toISOString();
    }
  }, [])
  const getDetailedCollection = useCallback(() => {
    dispatch("showSpinner");
    if (isTemporary)
    {
      triggerAPI(
        {
          url: 'community/collection/temp/get-one',
          data: {
            id: Number(id),
          },
          method: "post",
        },
        getDetailedCollectionResult
      );
    }
    else
    {
      const params = new URLSearchParams({
       id
      });        
      triggerAPI(
        {
          url: `community/collection/get-one?${params}`,            
          method: "get",
        },
        getDetailedCollectionResult
      );
    }        
  }, [dispatch, isTemporary, triggerAPI, id, getDetailedCollectionResult]);
  useEffect(() => {
    if(props?.community){
      setCommunityList([props?.community]);
    }
   else{ 
    if (details?.createdBy || details?.user?.id) getCommunityList();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.createdBy, details?.user?.id]);

  useEffect(() => {
    if(dataFetched?.communityList && dataFetched?.data) {
      const tempList = communityList?.filter((list => list?.id === formValues?.communityId))
      setCommunityList(tempList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);
  const getCommunityListResult = useCallback(
    (res) => {
      const { data } = res;
      setCommunityList(data?.list);
      setDataFetched((prev) => {
        return { ...prev, communityList: true };
      });
    },
    []
  );
  const getCommunityList = useCallback(() => {
    triggerAPI(
      {
        url: `community/get`,
        method: "post",
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
      },
      getCommunityListResult
    );
  }, [getCommunityListResult, triggerAPI]);

  const gotToBack = () => {
    if(props?.onClosePopup){
      props?.onClosePopup();
    }else{
      navigate(-1);
    }
  };

  const copytoClip = (e) => {
    const { id } = e.currentTarget.dataset;
    copyClipHandler(id, (res) => {
      res &&
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
    });
  };
  
  const reFetchCollectionResult = useCallback((res) => {
    const { data } = res;
    setDetails(prev => {return {...prev, 
      ownerCount: data?.ownerCount,
      mintDate: dateValidate(data?.mintDate),
      lockDate: dateValidate(data?.lockDate),
      assetCount: data?.assetCount      
    }});
    
    setMetricsData(prev => {       
      if (!metricsData?.metrics?.length)
      {
        metricsData?.metrics?.push({});
      }
      if (prev?.metrics?.[0])
      {
        prev.metrics[0].supply = data?.assetCount;
        prev.metrics[0].owners = data?.ownerCount;
      }
      return prev;      
    });
        
    setDisplayPicture(data?.filePath);
    setCollagePicture(data?.collage);
    setCollectionLoading(false)
    setCollectionImageLoading(false);
    setCollagePictureLoading(false);
    setReloading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[metricsData]);
  const reFetchCollectionData = (overrideImage, deleteAssets,collectionId) => {
    if (overrideImage)
    {
      // setCollectionImageLoading(true);
      setCollagePictureLoading(true);
      setReloading(true);
    }
    else
    {
      setCollectionLoading(true);
    }    
    triggerAPI(
      {
        url: `user/nft-collection/collectiondata`,
        method: "post",
        data: {
          id:id||collectionId||details?.id,
          networkType: "MAINNET",
          assetName: sampleAssetData?.asset_name,
          isTemporary,
          overrideImage,
          includeCounts:!overrideImage,
          deleteAssets:deleteAssets
        }
      },
      reFetchCollectionResult
    );
  }  

  const menuTabs = useMemo(()=>{
    let tabList= [{ id: "details", element: 'Details'},{ id: "pieces", element: 'Pieces' }]
    if(proofOfCrafts?.length) tabList.push({ id: "proofOfCraft", element: 'Proof of Craft', count: proofOfCrafts?.length })
    return tabList
  },[proofOfCrafts]);


  const handleDetailsPopup = (piece) => {
    const collectionId = <div className="">
      {!!details?.collectionId && (
        <div className="content flex items-center gap-[0.6rem] !text-[1rem]">
          {!!blockchainData && <img
            className="w-[1.3rem]"
            alt="Img"
            src={getBlockchainIcon(blockchainData?.code)}
          />}
          <p className="mt-1">
            {details?.collectionId?.length < 11
              ? details?.collectionId
              : details?.collectionId?.slice(0, 4) +
              "..." +
              details?.collectionId?.slice(-4)}
          </p>
          <img
            onClick={copytoClip}
            data-id={details?.collectionId}
            className="cursor-pointer w-4"
            alt="Img"
            src={copyIcon}
          />
        </div>
      )}
    </div>
    setPieceDetails({piece, community: communityList?.length ? communityList[0]: null, collectionId});
    setDetailsPopup(true);
}

const handleTabChange = (e) => {
  setActiveTab(e)
}
const setAsThumbnailHandler = async (thumbnail) => {
  try {
    const file = await stringImageSrcToBlob(thumbnail); // Await the result here
    inputOnChange({ file });
    setDisplayPicture(thumbnail);
  } catch (error) {
    console.error('Error fetching and converting image:', error);
  }
};
const communitySocialsClicked = (e) => {
  if(isCollectionValid){
    let communitySocials={
      website: communityList[0]?.website??"",
      twitter: communityList[0]?.twitter??"",
      instagram: communityList[0]?.instagram??"",
      discord: communityList[0]?.discord??"",
      youtube: communityList[0]?.youtube??""
    }
  setFormvalues((prev) => {
    return {
      ...prev,
      ...communitySocials,
    };
  });
}
}
useEffect(()=>{
  if(isCollectionValid&&!formValues?.isCustomSocial){
    communitySocialsClicked();
  }else if(isCollectionValid&&details?.id){
    let collectionSocials={
      website: details?.website??"",
      twitter: details?.twitter??"",
      instagram: details?.instagram??"",
      discord: details?.discord??"",
      youtube: details?.youtube??""
    }
    setFormvalues((prev) => {
      return {
        ...prev,
        ...collectionSocials
      };
    });
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[isCollectionValid,communityList,formValues?.isCustomSocial,details])
const toggleSwitch = (id, checked,keyName) => {
  inputOnChange({
      [keyName]: checked
  });
}
  return (
    <div className={`edit-collection mb-3 ${!isVerfifyPage&&"editing-collection"} ${!collectionId && 'top-collection-id-enter-section-active'}`}>
      <div className="w-full flex items-center justify-between px-[1rem]" >
        <span />
        <h2 className="!text-[1rem]">{isVerfifyPage ? 'Add' : 'Edit'} Collection</h2>
        <button onClick={gotToBack}><img src={closeIcon} alt="close" /></button>
      </div>
      {!collectionId  && <div className="flex flex-col gap-4 p-4">
              <div className="flex w-full gap-4 sm:flex-row flex-col">
                <div className="flex gap-4 w-full">
                  <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem]`}>
                    <div className="collection-form_blockchain-select-box">
                    <SelectBox
                    validateOnBlur
                    disabled={collectionId||(isCollectionValid)}
                    list={blockchainTypeList}
                    required={true}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Blockchain"
                    className="frame-form"
                    controlName="blockchainTypeId"
                    onChange={inputOnChange}
                    currentId={formValues.blockchainTypeId}
                    tabIndex={0}
                  />
                      <Validators type="Required" value={formValues.blockchainTypeId} message="Required" controlName="blockchainTypeId" onErrors={collectErrors} />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 w-full">
                  <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem]`}>
                    <div className="collection-form_blockchain-select-box">
                    <SelectBox
                    validateOnBlur
                    disabled={collectionId||(isCollectionValid)||!showBlockchainSourceList}
                    list={blockchainSourceList}
                    required={true}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Source"
                    className="frame-form"
                    controlName="blockchainSource"
                    onChange={inputOnChange}
                    currentId={formValues.blockchainSource}
                    tabIndex={0}
                  />                 
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 w-full">
                  <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem]`}>
                    <div>
                      <InputBox
                        type="text"
                        placeholder={blockchainTypePlaceholder ?? "Collection ID"}
                        label="Collection ID"
                        validateOnBlur
                        onChange={inputOnChange}
                        controlName="collectionId"
                        value={formValues.collectionId}
                        disabled={!!collectionId||isCollectionValid}
                      >
                        <Validators
                          type={formValues.collectionId ? "String" : "Required"}
                          value={formValues.collectionId}
                          message={
                            formValues.collectionId
                              ? "Must be string"
                              : "Required"
                          }
                          controlName="collectionId"
                          onErrors={collectErrors}
                        />
                      </InputBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
{!!isCollectionValid&&   <>
    <Tabs tabList={menuTabs} containerClass={`${!formValues?.collectionId && "cursor-not-allowed"} p-4`} selectedTab={activeTab} handleTab={handleTabChange} />

      {activeTab==="details" ? <>
      <div className="details-main-container">

          <div className="flex items-center gap-[4rem]">
            <h2 className="!text-[1rem]">{details?.name}</h2>
            {!!details?.collectionId && (
              <div className="content flex items-center gap-[0.6rem] !text-[1rem]">
                {!!blockchainData && <img
                    className="w-[1.3rem]"
                    alt="Img"
                    src={getBlockchainIcon(blockchainData?.code)}
                  />}
                <p className="mt-1">
                  {details?.contractAddress?.length < 11
                    ? details?.contractAddress
                    : details?.contractAddress?.slice(0, 4) +
                    "..." +
                    details?.contractAddress?.slice(-4)}
                </p>
                <img
                  onClick={copytoClip}
                  data-id={details?.contractAddress}
                  className="cursor-pointer w-4"
                  alt="Img"
                  src={copyIcon}
                />
              </div>
            )}
          </div>

          <MetricsData data={metricsData} shapes={shapes}/>

          <div className="flex gap-[1rem] mt-[1rem] w-full">
            <div className="thumbnail_image w-1/2 flex flex-col gap-2">
                <ImageUploadWithCrop
                  imageHandle={handleCroppedImage}
                  uploadedImage={displayPicture}
                  label={`Thumbnail*`}
                  inputId="upload-button-displayPicture"
                  type={2}
                  keyName="displayPicture"
                  id="pro-pic"
                  croppedLabel="Thumbnail"
                  isCropAspect={4 / 4}
                  recommendedSize={`1024px x 1024px`}
                  canvasStyle={{
                    maxWidth: '80%',
                    objectFit: "cover",
                    borderRadius: '12px',
                    margin: 'auto',
                  }}
                  isSquareImage
                />
              {/* <Validators type="Required" value={formValues.file}
               message="Required" controlName="file" onErrors={collectErrors} /> */}
            </div> 

            <div className="thumbnail_image w-1/2 flex flex-col gap-2">
              <div className="flex items-center gap-4">
                <p className="label-text">Collage</p>
                <div className="flex justify-end cursor-pointer reload-section">
                  <img src={ReloadIcon} className={`${reloading && `animate-spin`} ${reloading || collagePictureLoading ? 'cursor-not-allowed' : ''}`} alt="" onClick={(e) =>{
                    if(reloading || collagePictureLoading) return;
                    if(!isCollectionFoundSearch){ // if the collection is not in the database this api is getting error so this api get only called only for edit collections
                      reFetchCollectionData(true, false);
                    }else{
                      updateCollage(details?.collectionId, details.blockchainTypeId);   
                    }
                     }} title="Click to refresh the collage."/>
                </div>
              </div>
              <div
                className="upload-banner-image profile-img displayPictureCollection">
                {!collagePictureLoading && collagePicture ? (
                  <img
                    alt="No Img"
                    className={`image-uploaded-collage`}
                    src={collagePicture}
                  />)
                  :
                  collagePictureLoading ? <div className={`image-uploaded-collage`}><SkeletonLoader /></div> 
                    :
                    <div className="h-full w- full flex justify-center items-center">
                      <p className="inline-block w-1/2 text-center text-gray-300">
                        Click the reload button to generate collage image
                      </p>
                    </div>}
              </div>
            </div>

          </div>


      <div>
        <div className="banner flex gap-2 mt-3">
          <div className="details-section">
            <div className="flex justify-end cursor-pointer reload-section">
              <img src={ReloadIcon} alt="" title="Click to refresh collection details. This will also refresh all assets in this collection in our db." onClick={(e)=>{
                 if(!isCollectionFoundSearch){ // if the collection is not in the database this api is getting error so this api get only called only for edit collections
                  reFetchCollectionData(false, true);
                }else{
                  updateCollage(details?.collectionId, details.blockchainTypeId);   
                }
              }} />
            </div>
            <div className="details-box">
              <p className="title">Collection</p>
              <p className="content">{details?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Blockchain</p>
              <p className="content">{details?.blockchainType?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Collection ID</p>
              {!!details?.collectionId && (
                <p className="content flex">
                  {details?.collectionId?.length < 11
                    ? details?.collectionId
                    : details?.collectionId?.slice(0, 4) +
                      "..." +
                      details?.collectionId?.slice(-4)}
                  <img
                    onClick={copytoClip}
                    data-id={details?.collectionId}
                    className="cursor-pointer w-4 ml-2.5"
                    alt="Img"
                    src={copyIcon}
                  />
                </p>
              )}
            </div>
            {!!details?.contractAddress && (<div className="details-box">
              <p className="title">Contract Address</p>
              
                <p className="content flex">
                  {details?.contractAddress?.length < 11
                    ? details?.contractAddress
                    : details?.contractAddress?.slice(0, 4) +
                      "..." +
                      details?.contractAddress?.slice(-4)}
                  <img
                    onClick={copytoClip}
                    data-id={details?.contractAddress}
                    className="cursor-pointer w-4 ml-2.5"
                    alt="Img"
                    src={copyIcon}
                  />
                </p>              
            </div>)}
            <div className="details-box">
              <p className="title">Items</p>
              <p className="content">{collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : details?.assetCount}</p>
            </div>
            <div className="details-box">
              <p className="title">Owners</p>
              <p className="content">{collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : details?.ownerCount}</p>
                
            </div>
            <div className="details-box">
              <p className="title">Community</p>
              <p className="content">{details?.community?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Mint/Lock Date</p>
              <div className="content">
                {collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : (details?.mintDate && (
                  <FormatField
                    type="dayMonthYearDate"
                    value={details?.mintDate}
                  />
                ))}
                /
                {collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : (details?.lockDate && (
                  <FormatField
                    type="dayMonthYearDate"
                    value={details?.lockDate}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="community-form collection text-left flex flex-col gap-4 mt-4">
    { !isVerfifyPage  && <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Collection ID</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={blockchainTypeList}
                    required={false}
                    placeHolder="Select"
                    label="Blockchain"
                    keyName={"id"}
                    className="frame-form"
                    controlName="blockchainTypeId"
                    onChange={inputOnChange}
                    disabled={!!isEditPage}
                    currentId={formValues.blockchainTypeId}
                    tabIndex={0}
                  />
                  <Validators
                    type="Required"
                    value={formValues.blockchainTypeId}
                    message="Required field"
                    controlName="blockchainTypeId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    type="text"
                    placeholder={"Collection ID"}
                    label="Collection ID"
                    validateOnBlur
                    required={true}
                    onChange={inputOnChange}
                    controlName="collectionId"
                    value={formValues.collectionId}
                    disabled={!!isEditPage}
                  />
                  <Validators
                    type="Required"
                    value={formValues.collectionId}
                    message="Required field"
                    controlName="collectionId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>}
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">
              Collection Details
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
             
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-2 ">
                  <div className="w-full">
                    <InputBox
                      maxlength={35}
                      type="text"
                      label="Name"
                      validateOnBlur
                      required={true}
                      onChange={inputOnChange}
                      controlName="name"
                      value={formValues.name}
                    />
                  </div>
                  <Validators type="Required" value={formValues.name}
               message="Required" controlName="name" onErrors={collectErrors} />
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <TextArea
                      placeholder={""}
                      label="Description"
                      validateOnBlur
                      type="text"
                      required={true}
                      rows={4}
                      onChange={inputOnChange}
                      maxLength={300}
                      showTextCount={true}
                      controlName="description"
                      value={formValues.description}
                    />
                  </div>
                  {/* <Validators type="Required" value={formValues.description}
               message="Required" controlName="description" onErrors={collectErrors} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Categorization</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={category}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Category"
                    className="frame-form"
                    controlName="categoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.categoryId}
                    tabIndex={0}
                  />
                </div>
                    <Validators
                      type="Required"
                      value={formValues?.categoryId}
                      message="Required field"
                      controlName="categoryId"
                      onErrors={collectErrors}
                    />
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={[...firstSubCategory]}
                    required={true}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Sub-Category"
                    className="frame-form"
                    controlName="firstSubcategoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.firstSubcategoryId}
                    tabIndex={0}
                  />
                </div>
                    <Validators
                      type="Required"
                      value={formValues?.firstSubcategoryId}
                      message="Required field"
                      controlName="firstSubcategoryId"
                      onErrors={collectErrors}
                    />
              </div>
            </div>
            {/* <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={[...secondSubCategory]}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Sub-Category-2"
                    className="frame-form"
                    controlName="secondSubcategoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.secondSubcategoryId}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Formats (Choose all that apply)</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionFormatList?.map((format) => (
                  <span
                    key={format?.id}
                    className={
                      formValues?.collectionFormatIds?.includes(format?.id) ? `active` : ""
                    }
                    data-id={format?.id}
                    onClick={handleCollectionFormat}
                  >
                    {format?.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Types (Choose all that apply)</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionTypeList?.map((type) => (
                  <span
                    key={type?.id}
                    className={
                      formValues?.collectionTypeIds?.includes(type?.id) ? `active` : ""
                    }
                    data-id={type?.id}
                    onClick={handleCollectionType}
                  >
                    {type?.name}
                  </span>
                ))}
              </div>                  
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Storage</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionStorageList?.map((storage) => (
                  <span
                    key={storage?.id}
                    className={
                      formValues?.collectionStorageId === storage?.id ? `active` : ""
                    }
                    data-id={storage?.id}
                    onClick={handleCollectionStorage}
                  >
                    {storage?.name}
                  </span>
                ))}
              </div>
              {/* <Validators
                type="Required"
                value={formValues?.collectionStorageId}
                message="Required field"
                controlName="collectionStorageId"
                onErrors={collectErrors}
              /> */}
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Editions</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionEditionsList?.map((edition) => (
                  <span
                    key={edition?.id}
                    className={
                      formValues?.collectionEditionsId === edition?.id ? `active` : ""
                    }
                    data-id={edition?.id}
                    onClick={handleCollectionEdition}
                  >
                    {edition?.name}
                  </span>
                ))}
              </div>
              {/* <Validators
                type="Required"
                value={formValues?.collectionEditionsId}
                message="Required field"
                controlName="collectionEditionsId"
                onErrors={collectErrors}
              /> */}
            </div>
          </div>        
              <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="flex flex-col gap-2">
                  <label>NSFW</label>
                </div>
                <div className="flex items-center gap-[1rem] w-[min-content]">
                  <Button
                    label="No"
                    onClick={() => setNsfwValue('no')}
                    className={`common_button ${nsfwValue === 'no' && 'active'}`}
                  />
                  <Button
                    label="Yes"
                    onClick={() => setNsfwValue('yes')}
                    className={`common_button ${nsfwValue === 'yes' && 'active'}`}
                  />
                </div>
              </div>    
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Tags (Choose all that apply)</label>
            </div>
            <div>
              <div className={"flex mb-2"} style={{width:'20rem'}}>
                <InputBox                      
                  maxlength={100}
                  type="text"                                       
                  placeholder="Enter New Tag..."
                  onChange={inputOnChange}
                  width={10}  
                  controlName="newtag"    
                  value={formValues.newtag}                                                                            
                >
                </InputBox>                                           
                <Button
                  label="Save Tag"
                  onClick={handleNewCollectionTag}
                  className="transaction-cancel savetag"
                />
              </div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionTagList?.map((tag) => (
                  <span
                    key={tag?.id}
                    className={
                      formValues?.collectionTagIds?.includes(tag?.id) ? `active` : ""
                    }
                    data-id={tag?.id}
                    onClick={handleCollectionTag}
                  >
                    {tag?.name}
                  </span>
                ))}                    
              </div>                  
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Utility</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionUtilityList?.map((list) => (
                  <span
                    key={list?.id}
                    className={
                       formValues?.collectionUtilitiesIds?.includes(list?.id) ? `active` : ""
                    }
                    data-id={list?.id}
                    onClick={handleCollectionUtility}
                  >
                    {list?.name}
                  </span>
                ))}
              </div>
            </div>
          </div> 
        </div>
    { !isVerfifyPage  && <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Community*</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div className="community-list-container flex gap-[1.42857rem] body flex-wrap">
                  {communityList?.map((community) => {
                    return (
                      <div
                        title={community?.name}
                        className={`item-container cursor-not-allowed ${
                          formValues.communityId === community?.id && "active"
                        }`}
                        data-id={community?.id}
                        key={community?.id}
                        // onClick={handleCommunity}
                      >
                        <div className="image-container">
                          <img src={community?.displayPicture} alt="" />
                        </div>
                        <div className="title-container py-[0.72rem]">
                          <span>{community?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>}
        <div className="flex flex-col gap-4">
          <div className="flex flex-row sm:gap-2 w-full gap-3 brd-btm">
            <div className="section-title ">Custom Socials</div>
            <ToggleSwitch check={formValues?.isCustomSocial} row={{}} keyName="isCustomSocial" onChange={toggleSwitch} />
          </div>
          <div className="flex flex-col w-full gap-4 socials-wrapper">
              <div className="flex w-full flex-col gap-2">
                <div>
                  <InputBox
                    placeholder="https://www.website.com"
                    label="Website"
                    validateOnBlur
                    type="text"
                    disabled={!formValues?.isCustomSocial}
                    required={false}
                    onChange={inputOnChange}
                    controlName="website"
                    value={formValues.website}
                  />
                  <Validators
                    type="url"
                    value={formValues.website}
                    message="URL not valid"
                    controlName="website"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    placeholder="X URL"
                    label="Twitter"
                    validateOnBlur
                    type="text"
                    disabled={!formValues?.isCustomSocial}
                    required={false}
                    onChange={inputOnChange}
                    controlName="twitter"
                    value={formValues.twitter}
                  />
                  <Validators
                    type="twitterUrl"
                    value={formValues.twitter}
                    message="URL not valid"
                    controlName="twitter"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Instagram"
                    placeholder="Instagram URL"
                    validateOnBlur
                    type="text"
                    disabled={!formValues?.isCustomSocial}
                    required={false}
                    onChange={inputOnChange}
                    controlName="instagram"
                    value={formValues.instagram}
                  />
                  <Validators
                    type="instagramUrl"
                    value={formValues.instagram}
                    message="URL not valid"
                    controlName="instagram"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Discord"
                    placeholder="Discord URL"
                    validateOnBlur
                    type="text"
                    disabled={!formValues?.isCustomSocial}
                    required={false}
                    onChange={inputOnChange}
                    controlName="discord"
                    value={formValues.discord}
                  />
                  <Validators
                    type="discordUrl"
                    value={formValues.discord}
                    message="URL not valid"
                    controlName="discord"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Youtube"
                    placeholder="Youtube URL"
                    validateOnBlur
                    type="text"
                    disabled={!formValues?.isCustomSocial}
                    required={false}
                    onChange={inputOnChange}
                    controlName="youtube"
                    value={formValues.youtube}
                  />
                  <Validators
                    type="youtubeUrl"
                    value={formValues.youtube}
                    message="URL not valid"
                    controlName="youtube"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              {/* <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Email"
                    placeholder="email@domain.com"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="email"
                    value={formValues.email}
                  />
                  <Validators
                    type="Email"
                    value={formValues.email}
                    message="Email not valid"
                    controlName="email"
                    onErrors={collectErrors}
                  />
                </div>
              </div> */}
            </div>
        </div>
        <div className="flex flex-col gap-4">
            <TermsConditions/>
          <div
            className={`${isEditPage && "disabled"} flex flex-col w-full gap-4`}
          >
            <TermsTable
              termsList={termsList}
              handleTerms={handleTerms}
              values={formValues?.termsId}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Marketplace Websites</div>
          </div>          
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Primary Mint URL"
                    validateOnBlur
                    placeholder="https://www.mintingsite.com/link"
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="primaryMintUrl"
                    value={formValues.primaryMintUrl}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Secondary Trade URL"
                    validateOnBlur
                    placeholder="https://www.marketplace.com/link"
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="marketplace"
                    value={formValues.marketplace}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="flex flex-col gap-4 buttons_container">
          <div className="flex justify-center gap-4 pt-4">
            <Button
              label="Cancel"
              onClick={gotToBack}
              className="transaction-cancel"
            />
            <Button
              className={`transaction-ok ${!isValid ? "disable" : ""}`}
              label={isVerfifyPage ? 'Add' :`Update`}
              onClick={saveHandler}
            />
          </div>
        </div>
      </>
        : (activeTab === "proofOfCraft") ? <ProofOfCraft
          proofOfCrafts={proofOfCrafts}
          onProofOfCrafts={setProofOfCrafts}
          deletedProofOfCrafts={proofOfCrafts}
          onDeleteProofOfCrafts={setDeletedProofOfCrafts}
        />
      : 
      <>
        <PiecesList setAsThumbnailHandler={setAsThumbnailHandler} collectionInfo={collectionInfo} onSelectHandler={handleDetailsPopup}/>
      </>}
</>}
      {/* Piece Details Popup */}
      {detailsPopup && <PieceDetailsView
        onClosePopup={() => {
          setPieceDetails(null);
          setDetailsPopup(false);
        }}
        openPopup={detailsPopup}
        data={pieceDetails}
      /> }
      {creatorScreenshotInfo && <CreatorCard {...creatorScreenshotInfo} setScreenshotTaken={setScreenshotTaken} />}
    </div>
  );
};

export default EditCollection;
